/** CONSTANTS */
import {
	fundingDefaultDuration,
	fundingDefaultMainBorrowerMonthlyIncomes,
	fundingDefaultMonthlyCredit,
	fundingToApiMaping,
} from "./funding.constant";
import { PropertyTypes } from "domain/constants";

/** DTOs */
import { FundingDTO, UpdateFundingDTO } from "./funding.dto";

/** EXCEPTIONS */
import { InvalidValueForFunding } from "./funding.exceptions";

/** MODEL */
import { Funding } from "./funding.model";

/** REPOSITORIES */
import { IFundingService } from "./funding.entities";

/** TYPES */
import { PropertyType } from "domain/types";

/** UTILS */
import { formatPropertyType } from "domain/utils";

export const FundingService: IFundingService = {
	formatFromApi: (data: FundingDTO): Funding => ({
		id: data.id,
		contribution: data.personalContribution,
		debtRatio: data.debtRatio,
		duration: data.duration,
		fees: data.fees,
		mainBorrowerMonthlyIncomes: data.mainBorrowerMonthlyIncomes,
		monthlyIncomes: data.monthlyIncomes,
		monthlyPayment: data.result && data.result > 0 ? data.result : 0,
		propertyPrice: data.price,
		propertyType: formatPropertyType(data.type),
		monthlyCredits: data.monthlyCredits,
		rate: data.rate,
		source: data.source ?? "widget",
		widgetSource: data.widgetSource ?? "widget",
	}),

	formatToAPI: (payload: Partial<Funding>): Partial<UpdateFundingDTO> => {
		const tmp: Partial<UpdateFundingDTO> = {};

		Object.keys(payload).forEach((key) => {
			const name = fundingToApiMaping[key] as keyof UpdateFundingDTO;
			const value: any = payload[key as keyof Funding];

			tmp[name] = value;
		});

		if (payload.propertyType) {
			tmp.type = PropertyTypes[payload.propertyType];
		}

		return tmp;
	},

	checkValue: (name: string, value: number): void => {
		if (value < 0) throw new InvalidValueForFunding(name, value, "checkValue");
	},

	calculateDefaultContribution: (
		price: number,
		contributionPercentage: number = 15
	) => Math.ceil((price / 100) * contributionPercentage),

	getDefaultFunding: (
		propertyPrice: number,
		propertyType: PropertyType,
		source: string
	): Partial<Funding> => ({
		contribution: FundingService.calculateDefaultContribution(propertyPrice),
		propertyPrice,
		propertyType,
		duration: fundingDefaultDuration,
		monthlyCredits: fundingDefaultMonthlyCredit,
		mainBorrowerMonthlyIncomes: fundingDefaultMainBorrowerMonthlyIncomes,
		source,
		widgetSource: source ?? "widget",
	}),
};
