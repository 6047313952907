/** TYPES */
import { Duration } from "domain/types";

export const fundingToApiMaping: Record<string, string> = {
	id: "id",
	contribution: "personalContribution",
	debtRatio: "debtRatio",
	duration: "duration",
	fees: "fees",
	mainBorrowerMonthlyIncomes: "mainBorrowerMonthlyIncomes",
	monthlyIncomes: "monthlyIncomes",
	monthlyPayment: "result",
	monthlyCredits: "monthlyCredits",
	propertyPrice: "price",
	propertyType: "type",
	rate: "rate",
	source: "source",
	widgetSource: "widgetSource",
};

// Equal to a salary of the 30 000 - 49 999 yearly incomes
export const fundingDefaultMainBorrowerMonthlyIncomes = 3000;
export const fundingDefaultDuration: Duration = 25;
export const fundingDefaultMonthlyCredit: number = 0;
