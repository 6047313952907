/** INSTANCES */
import { httpAxios } from "infrastructure/instances/httpAxios.instance";

/** MODELS */
import { Funding } from "../../../domain/funding/funding.model";

/** PORTS */
import { FundingPort } from "../../../infrastructure/ports/funding.repository";

/** SERVICES */
import { FundingService } from "domain/funding/funding.service";

/** UTILS */
import { formatPropertyType } from "domain/utils";

const client = httpAxios;

export const createFundingUseCase = async (
	propertyPrice: number,
	propertyType: string,
	source: string,
): Promise<Funding> => {
	return await FundingPort(client).create(
		FundingService.getDefaultFunding(
			propertyPrice,
			formatPropertyType(propertyType),
			source
		)
	);
};
