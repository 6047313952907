import { useCallback, useEffect, useMemo, useState } from "react";
import "./Funding.scss";

/** COMPONENTS */
import FundingCategories from "ui/components/funding/FundingCategories/FundingCategories.component";
import FundingResult from "ui/components/funding/FundingResult/FundingResult.component";
import FundingGoToSimulation from "ui/components/funding/FundingGoToSimulation/FundingGoToSimulation.component";

/** HOOKS */
import { useBreakpoint } from "ui/hooks/useBreakpoint.hook";

/** MODELS */
import { Funding } from "domain/funding/funding.model";

/** REACT ROUTER DOM */
import { useSearchParams } from "react-router-dom";

/** SVGs */
import { ReactComponent as FridaaLogo } from "ui/assets/svg/fridaa-logo-yellow-background.svg";

/** TYPES */
import { Source } from "domain/types";

/** USE CASES */
import { createFundingUseCase } from "application/funding/use-cases/createFunding.use-case";
import { updateFundingUseCase } from "application/funding/use-cases/updateFunding.use-case";

/** UTILS */
import {
	getExternalFridaaWebsiteUriBasedOnSource,
	openInNewTab,
} from "ui/util";
import { getSource } from "domain/utils";

const FundingPage = () => {
	const { isXSmall, isSmall } = useBreakpoint();
	const [searchParams] = useSearchParams();
	const [funding, setFunding] = useState<Funding>();
	const [propertyPrice, propertyType, source]: [number, string, Source] =
		useMemo(
			() => [
				// || 0 is used to convert the potential NaN value from parseInt()
				parseInt(searchParams.get("propertyPrice") ?? "0") || 0,
				searchParams.get("propertyType") ?? "OLD",
				getSource(searchParams.get("source") ?? "widget"),
			],
			[searchParams]
		);

	useEffect(() => {
		if (propertyPrice !== null && propertyType !== null && source !== null) {
			createFundingUseCase(propertyPrice, propertyType, source)
				.then(setFunding)
				.catch((err) => console.error(err));
		}
	}, [propertyPrice, propertyType, source]);

	const handleChange = useCallback(
		async (name: string, value: number) => {
			if (!funding || !funding.id) return;

			try {
				updateFundingUseCase(funding.id, {
					...funding,
					[name]: value,
				}).then(setFunding);
			} catch (error) {
				console.error(error);
			}
		},
		[funding]
	);

	const handleGoToSimulation = useCallback(() => {
		const baseUri = getExternalFridaaWebsiteUriBasedOnSource(source);

		if (baseUri) {
			openInNewTab(
				`${baseUri}?propertyPrice=${funding?.propertyPrice}&fundingId=${funding?.id}`
			);
		}
	}, [funding?.propertyPrice, funding?.id, source]);

	return funding ? (
		<div id="funding-page">
			<FundingResult funding={funding} CBHandleClick={handleGoToSimulation} />
			<FundingCategories funding={funding} CBHandleChange={handleChange} />

			{(isXSmall || isSmall) && (
				<>
					<div className="fridaa-logo">
						<FridaaLogo />
					</div>
					<FundingGoToSimulation
						disabled={funding?.id == null}
						CBHandleClick={handleGoToSimulation}
					/>
				</>
			)}

			<div className="note">
				<p>
					*Simulation non contractuelle, donnée à titre informatif, se basant
					sur les taux moyens du marché, sans assurance et avec un emprunteur
					unique
				</p>
			</div>
		</div>
	) : null;
};

export default FundingPage;
