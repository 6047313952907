/** COMPONENTS */
import Layout from "./components/Layout/Layout.component";

/** PAGES */
import FundingPage from "./pages/funding/Funding.page";

/** REACT ROUTER DOM */
import { Navigate, Route, Routes } from "react-router-dom";

const App = () => (
	<Routes>
		<Route element={<Layout />}>
			<Route path="/funding" element={<FundingPage />} />
			<Route path="" element={<Navigate to="/funding" replace={true} />} />
		</Route>
	</Routes>
);

export default App;
